import secureLocalStorage from "react-secure-storage";

// secureLocalStorage.setItem("acessTokens", JSON.stringify(acessTokens));

let userData = JSON.parse(secureLocalStorage.getItem("userInfo"));

const cartCount = userData?.cartCount;
const userAccessToken = userData?.userAccessToken;
const user_id = userData?.user_id;
const earning_point = userData?.earning_point;
const points = userData?.points;
const redeemed_point = userData?.redeemed_point;
const role = userData?.role;
const privileges = userData?.privileges;
const name = userData?.name;
const wishlist_items_count = userData?.wishlist_items_count;
const wishIdz = userData?.wishIdz;

if (!userData) {
  userData = {};
}

const initialState = {
  cartCount: cartCount ? cartCount : 0,
  userAccessToken: userAccessToken ? userAccessToken : null,
  user_id: user_id ? user_id : null,
  earning_point: earning_point ? earning_point : null,
  points: points ? points : null,
  redeemed_point: redeemed_point ? redeemed_point : null,
  role: role ? role : null,
  privileges: privileges ? privileges : null,
  name: name ? name : null,
  wishlist_items_count: wishlist_items_count ? wishlist_items_count : 0,
  wishIdz: wishIdz ? wishIdz : null,
};

const reducer = (state = initialState, action) => {
  if (action.type === "userLogin") {
    userData.userAccessToken = action.payload.userAccessToken;
    userData.user_id = action.payload.user_id;
    userData.earning_point = action.payload.earning_point;
    userData.redeemed_point = action.payload.redeemed_point;
    userData.points = action.payload.points;
    userData.role = action.payload.role;
    userData.wishlist_items_count = action.payload.wishlist_items_count;
    userData.wishIdz = action.payload.wishIdz;
    userData.cartCount = action.payload.cartCount;
    userData.privileges = action.payload.privileges;
    userData.name = action.payload.name;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      userAccessToken: action.payload.userAccessToken,
      user_id: action.payload.user_id,
      earning_point: action.payload.earning_point,
      redeemed_point: action.payload.redeemed_point,
      points: action.payload.points,
      role: action.payload.role,
      wishlist_items_count: action.payload.wishlist_items_count,
      cartCount: action.payload.cartCount,
      wishIdz: action.payload.wishIdz,
      privileges: action.payload.privileges,
      name: action.payload.name,
    };
  } else if (action.type === "userLogOut") {
    secureLocalStorage.removeItem("userInfo");

    return {
      ...state,
      userAccessToken: null,
      user_id: null,
      earning_point: null,
      redeemed_point: null,
      points: null,
      role: null,
      privileges: null,
      name: null,
      wishlist_items_count: 0,
      wishIdz: null,
    };
  } else if (action.type === "wishCount") {
    userData.wishlist_items_count = action.payload.count;
    userData.wishIdz = action.payload.idz;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      wishlist_items_count: action.payload.count,
      wishIdz: action.payload.idz,
    };
  } else if (action.type === "cartCounter") {
    userData.cartCount = action.payload;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      cartCount: action.payload,
    };
  } else if (action.type === "updateName") {
    userData.name = action.payload;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      name: action.payload,
    };
  } else if (action.type === "pointsUpdate") {
    userData.earning_point = action.payload.earning_point;
    userData.redeemed_point = action.payload.redeemed_point;
    userData.points = action.payload.points;

    secureLocalStorage.setItem("userInfo", JSON.stringify(userData));

    return {
      ...state,
      earning_point: action.payload.earning_point,
      redeemed_point: action.payload.redeemed_point,
      points: action.payload.points,
    };
  } else {
    return state;
  }
};
export default reducer;
