import React from "react";

import ImageLoader from "./ImageLoader";

import AddToWish from "./AddToWish";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "../../../Redux";
import { bindActionCreators } from "redux";
import UserListService from "../../../services/user-list.service";

export default function FeaturedProductBox({
  id,
  img,
  discription,
  points,
  product_number,
  product_type,
  product_image,
  product_model,
  product_name,
  product_points,
}) {
  const dispatch = useDispatch();

  const userActions = bindActionCreators(actionCreaters, dispatch);

  const { userAccessToken } = useSelector((state) => state.stateVals);
  const addToCart = async () => {
    try {
      const response = await UserListService.addInCart({
        product_id: id,
        product_number: product_number,
        cat_type: product_type,
        product_type: product_type,
        product_image: product_image,
        product_model: product_model,
        product_name: product_name,
        product_points: product_points,

        userAccessToken: userAccessToken,
      });

      toast.success(response?.data?.message, {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      userActions.cartCounter(
        response?.data?.response?.cart_items_count,
        "cartCounter"
      );
    } catch (err) {
      console.log(err);
      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        toast.warn(err?.response?.data?.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.warn(err?.response?.data?.error, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };
  return (
    <div className="featured-product-box">
      <div className="border img">
        <AddToWish
          id={id}
          product_number={product_number}
          product_type={product_type}
        />
        <div className="featured-product-box-content">
          <ImageLoader
            src={img}
            className="img-product"
            alt="product-img"
            id={id}
            leadingHeight={110}
            leadingWidth="100%"
          />

          <p className="product-text">{discription}</p>
          <p className="product-point">
            Points: <span>{points}</span>
          </p>
          <button className="button" onClick={addToCart}>
            <i className="bi bi-basket"></i>Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
}
