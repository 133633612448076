import React, { useState } from "react";
import LogoutBox from "./LogoutBox";
import Logo from "./Logo";
import Navs from "./Navs";
import Categories from "./Categories";

import CartBox from "./CartBox";
import PointsBalance from "./PointsBalance";
import search from "../../../Images/search.png";

import { useNavigate } from "react-router-dom";

export default function Header() {
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const searchOverAllProducts = async () => {
    if (searchTerm?.trim() !== "") {
      navigate(`/search-product?search=${searchTerm.trim()}`);
    }
  };

  return (
    <>
      <div className="top-blue-boredr"></div>

      <div className="main-top">
        <div className="container">
          <div className="row">
            <ul className="top-logo-box top-logo-box-d">
              <Logo />
              <Categories />
              <li className="top-searh-box">
                <div className="d-flex top-search top-search-d">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyUp={(e) => {
                      if (e?.keyCode === 13) {
                        searchOverAllProducts();
                      }
                    }}
                  />
                  <button
                    className="btn"
                    type="button"
                    onClick={searchOverAllProducts}
                  >
                    <span>Search</span>{" "}
                    <img src={search} alt="Search" className="svg-icon" />
                  </button>
                  {/* {products.length ? (
                    <ul>
                      {products?.map((product) => (
                        <li key={product.id}>
                          <Link to={`/product-detail/${product.id}`}>
                            <div className="product-info">
                              <div className="img-holder">
                                <img src={product.image} alt="product-img" />
                              </div>
                              <div>{product.name}</div>
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : null} */}
                </div>
              </li>
              <LogoutBox />
            </ul>
          </div>

          <div className="row">
            <ul className="bg-light nav-box-d">
              <li className="nav-d">
                <nav className="navbar navbar-expand-lg">
                  <div className="">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNav"
                      aria-controls="navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                      <Navs />
                    </div>
                  </div>
                </nav>
              </li>
              <li className="top-user-d">
                <PointsBalance />
              </li>
              <CartBox />
            </ul>
          </div>

          <div className="row d-m">
            <div className="col-lg-12">
              <ul className="top-logo-box">
                <Logo />
                <Categories />
              </ul>
            </div>
          </div>
          <div className="row d-m">
            <div className="col-lg-12">
              <PointsBalance />
            </div>
          </div>
          <div className="row d-m">
            <div className="col-lg-12">
              <ul className="top-logo-box">
                <li className="top-searh-box">
                  <div className="d-flex top-search">
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      onKeyUp={(e) => {
                        if (e?.keyCode === 13) {
                          searchOverAllProducts();
                        }
                      }}
                    />
                    <button
                      className="btn"
                      type="button"
                      onClick={searchOverAllProducts}
                    >
                      <span>Search</span>{" "}
                      <img src={search} alt="Search" className="svg-icon" />
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
