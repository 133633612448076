import React from "react";
import { Link } from "react-router-dom";

export default function Navs() {
  return (
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link className="nav-link active" to="/view-reward">
          Home
        </Link>
      </li>
      {/* <li className="nav-item">
        <a className="nav-link" href="/">
          Coworkers Corner
        </a>
      </li> */}
      <li className="nav-item dropdown-under">
        <Link className="nav-link" href="/">
          My Account{" "}
          <svg
            width="14"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path>
          </svg>
        </Link>
        <ul className="dropdown-nav">
          <li>
            <Link to="/my-account"> Change Profile Information</Link>
          </li>
          <li>
            <Link to="/order-history">Order History</Link>
          </li>
          <li>
            <Link to="/refund-order-history">Refunded Order History</Link>
          </li>
        </ul>
      </li>

      <li className="nav-item dropdown-under">
        <Link className="nav-link" href="/">
          Information{" "}
          <svg
            width="14"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
          >
            <path d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z"></path>
          </svg>
        </Link>
        <ul className="dropdown-nav">
          <li>
            <Link to="/help">Help</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
        </ul>
      </li>
    </ul>
  );
}
