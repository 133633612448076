import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Images/logo.png";

export default function Logo() {
  return (
    <li className="logo">
      <Link to="/view-reward">
        <img src={logo} alt="site-logo" />
      </Link>
    </li>
  );
}
