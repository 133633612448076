import React from "react";
import user from "../../../Images/Maskgroup-1.png";
import logoutIco from "../../../Images/Maskgroup.png";

import { useDispatch, useSelector } from "react-redux";
import { actionCreaters } from "../../../Redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

export default function LogoutBox() {
  const { name } = useSelector((state) => state.stateVals);
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();
  const logout = async () => {
    await userActions.logOut("userLogOut");
    navigate("/");
  };
  return (
    <li className="logout-box">
      <ul className="top-user">
        <li>
          <img src={user} alt="user" />
          <span>
            Welcome: <span className="text-capitalize">{name}</span>
          </span>
        </li>
        <li style={{ cursor: "pointer" }} onClick={logout}>
          <img src={logoutIco} alt="logout" /> <span>Logout</span>
        </li>
      </ul>
    </li>
  );
}
