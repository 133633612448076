import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";

export default function MobileMenuFooter() {
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();
  const logout = async () => {
    await userActions.logOut("userLogOut");
    navigate("/");
  };
  return (
    <div className="container">
      <div className="top-logo-box-m">
        <div className="navigation">
          <ul>
            <li className="list active" data-color="#dc143c">
              <Link to="/view-reward">
                <span className="icon">
                  <i className="bi bi-house"></i>
                </span>
                <span className="title">Home</span>
              </Link>
            </li>
            <li className="list" data-color="#dc143c">
              <Link to="/order-history">
                <span className="icon">
                  <i className="bi bi-box-seam"></i>
                </span>
                <span className="title">My Orders</span>
              </Link>
            </li>
            <li className="list" data-color="#3c40c6">
              <Link to="/wishlist">
                <span className="icon">
                  <i className="bi bi-suit-heart"></i>
                </span>
                <span className="title">Favorite</span>
              </Link>
            </li>
            <li className="list" data-color="#05c46b">
              <Link to="/cart">
                <span className="icon">
                  <i className="bi bi-cart2"></i>
                </span>
                <span className="title">Cart</span>
              </Link>
            </li>
            <li className="list" data-color="#0fbcf9">
              <Link to="/my-account">
                <span className="icon">
                  <i className="bi bi-person"></i>
                </span>
                <span className="title">User</span>
              </Link>
            </li>
            <li className="list" data-color="#ffa801">
              <a onClick={logout}>
                <span className="icon">
                  <i className="bi bi-box-arrow-right"></i>
                </span>
                <span className="title">Logout</span>
              </a>
            </li>
            <div className="indicator"></div>
          </ul>
        </div>
      </div>
    </div>
  );
}
