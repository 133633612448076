import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

export default function ImageLoader(props) {
  const [image, setImage] = useState("");
  const [imageLoad, setImageLoad] = useState(false);
  const noImage =
    "https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1678783786/No_Image_Available_izjkwf.jpg";

  useEffect(() => {
    setImage(props.src);
  }, [props]);
  const onImageError = () => {
    setImage(noImage);
  };
  return (
    <>
      {!imageLoad && (
        // <div className="content-loader">
        //   <div className="sk-folding-cube active">
        //     <div className="loader-42"></div>
        //   </div>
        // </div>
        <Skeleton
          count={1}
          height={props.leadingHeight}
          width={props.leadingWidth}
          duration={0.8}
        />
      )}
      {props.className === "main-slider-img" ? (
        <img
          src={image}
          alt={props.alt}
          className={`${props.className} ${!imageLoad ? "d-none" : ""}`}
          onLoad={() => {
            setImageLoad(true);
          }}
          onError={onImageError}
        />
      ) : (
        <Link to={`/product-detail/${props.id}`}>
          <img
            src={image}
            alt={props.alt}
            className={`${props.className} ${!imageLoad ? "d-none" : ""}`}
            onLoad={() => {
              setImageLoad(true);
            }}
            onError={onImageError}
          />
        </Link>
      )}
    </>
  );
}
